/**
 * Sets up WebGL Program
 * @param {object} options
 * @param {object} options.context
 * @param {string} options.vertexShaderSource
 * @param {string} options.fragmentShaderSource
 * @return {object} program
 */
exports.setup = function (options = {
  context: null,
  vertexShaderSource: null,
  fragmentShaderSource: null
}) {
  const program = options.context.createProgram()
  const vertexShader = options.context.createShader(options.context.VERTEX_SHADER)
  const fragmentShader = options.context.createShader(options.context.FRAGMENT_SHADER)

  function compileShader (shader, source) {
    options.context.shaderSource(shader, source)
    options.context.compileShader(shader)

    const log = options.context.getShaderInfoLog(shader)
    if (log) throw new Error(log)
  }

  compileShader(vertexShader, options.vertexShaderSource)
  compileShader(fragmentShader, options.fragmentShaderSource)

  options.context.attachShader(program, vertexShader)
  options.context.attachShader(program, fragmentShader)

  options.context.linkProgram(program)
  options.context.useProgram(program)

  return program
}

/**
 * Sets attribute in WebGL Program
 * @param {object} options
 * @param {object} options.context
 * @param {object} options.program
 * @param {string} options.name
 * @param {array} options.data
 */
exports.setAttribute = function (options) {
  const attribPointer = options.context.getAttribLocation(options.program, options.name)
  const attribData = new Float32Array([].concat.apply([], options.data))
  const attribBuffer = options.context.createBuffer(options.context.ARRAY_BUFFER)
  options.context.bindBuffer(options.context.ARRAY_BUFFER, attribBuffer)
  options.context.bufferData(options.context.ARRAY_BUFFER, attribData, options.context.STATIC_DRAW)
  options.context.enableVertexAttribArray(attribPointer)
  const attributeSize = options.data[0].length
  const type = options.context.FLOAT
  const normalized = false
  const stride = 0
  const offset = 0
  options.context.vertexAttribPointer(attribPointer, attributeSize, type, normalized, stride, offset)
}

/**
 * Sets uniform in WebGL Program
 * @param {object} options
 * @param {object} options.context
 * @param {object} options.program
 * @param {string} options.name
 * @param {string} options.type
 * @param {array} options.data
 */
exports.setUniform = function (options) {
  const resolutionUniformLocation = options.context.getUniformLocation(options.program, options.name)
  options.context['uniform' + options.type](resolutionUniformLocation, options.data)
}

exports.setTexture = function (options) {
  return new Promise((resolve, reject) => {
    function loadImage (imageSrc) {
      const img = new Image()

      let _resolve
      const p = new Promise((resolve) => _resolve = resolve)

      img.onload = () => {
        _resolve(img)
      }

      img.src = imageSrc

      return p
    }

    loadImage(options.src).then((textureImg) => {
      const texture = options.context.createTexture()
      options.context.bindTexture(options.context.TEXTURE_2D, texture)
      options.context.pixelStorei(options.context.UNPACK_FLIP_Y_WEBGL, 1)
      options.context.texImage2D(
        options.context.TEXTURE_2D,
        0,
        options.context.RGBA,
        options.context.RGBA,
        options.context.UNSIGNED_BYTE,
        textureImg
      )

      options.context.texParameteri(options.context.TEXTURE_2D, options.context.TEXTURE_WRAP_S, options.context.CLAMP_TO_EDGE)
      options.context.texParameteri(options.context.TEXTURE_2D, options.context.TEXTURE_WRAP_T, options.context.CLAMP_TO_EDGE)
      options.context.texParameteri(options.context.TEXTURE_2D, options.context.TEXTURE_MIN_FILTER, options.context.LINEAR)
      options.context.texParameteri(options.context.TEXTURE_2D, options.context.TEXTURE_MAG_FILTER, options.context.LINEAR)

      options.context.activeTexture(options.context.TEXTURE0)
      const resolutionUniformLocation = options.context.getUniformLocation(options.program, 'texture')
      options.context.uniform1i(resolutionUniformLocation, 0)

      resolve()
    })
  })
}
