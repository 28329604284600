import WebGLUtils from './WebGLUtils'
import vertexShaderSource from './../shaders/vertex.glsl'
import fragmentShaderSource from './../shaders/fragment.glsl'

export default function (element, sourceVideo, parent) {
  const canvas = document.querySelector(element)
  const width = document.querySelector(parent).clientWidth
  const height = document.querySelector(parent).clientHeight

  canvas.width = width * devicePixelRatio
  canvas.height = height * devicePixelRatio

  canvas.style.width = `${width}px`
  canvas.style.height = `${height}px`
  const gl = canvas.getContext('webgl')

  gl.canvas.width = width * devicePixelRatio
  gl.canvas.height = height * devicePixelRatio

  const program = WebGLUtils.setup({
    context: gl,
    vertexShaderSource,
    fragmentShaderSource
  })

  WebGLUtils.setAttribute({
    context: gl,
    program,
    name: 'position',
    data: [
      [-1.0, 1.0],
      [1.0, 1.0],
      [-1.0, -1.0],
      [1.0, -1.0]
    ]
  })

  WebGLUtils.setUniform({
    context: gl,
    program,
    name: 'resolution',
    type: '2fv',
    data: [canvas.width, canvas.height]
  })

  // WebGLUtils.setTexture({
  //   context: gl,
  //   program,
  //   src: textureImageSrc
  // }).then(() => {
  //   gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4)
  // })

  gl.viewport(0, 0, gl.canvas.width, gl.canvas.height)

  var copyVideo = false
  var playing = false
  var timeupdate = false

  function checkReady () {
    if (playing && timeupdate) {
      copyVideo = true
    } else {
      copyVideo = false
    }
  }

  function setupVideo (url) {
    const video = document.createElement('video')

    video.autoplay = true
    video.muted = true
    video.loop = true
    video.playsInline = true
    video.preload = 'auto'
    video.autoload = true
    video.addEventListener('playing', () => {
      playing = true
      checkReady()
    }, true)
    video.addEventListener('timeupdate', () => {
      // Don't render incomplete frame caused by hiccup when looping back around
      // Fixes "dimensions of `level_base` are not all positive" error
      if (video.currentTime === 0) {
        timeupdate = false
      } else {
        timeupdate = true
      }
      checkReady()
    }, true)

    video.src = url
    video.play()

    return video
  }

  function initTexture (gl) {
    const texture = gl.createTexture()
    gl.bindTexture(gl.TEXTURE_2D, texture)

    const level = 0
    const internalFormat = gl.RGBA
    const width = 1
    const height = 1
    const border = 0
    const srcFormat = gl.RGBA
    const srcType = gl.UNSIGNED_BYTE
    const pixel = new Uint8Array([0, 0, 0, 255]) // opaque blue
    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat,
      width, height, border, srcFormat, srcType,
      pixel)

    // Turn off mips and set  wrapping to clamp to edge so it
    // will work regardless of the dimensions of the video.
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)

    return texture
  }

  function updateTexture (gl, texture, video) {
    const level = 0
    const internalFormat = gl.RGBA
    const srcFormat = gl.RGBA
    const srcType = gl.UNSIGNED_BYTE
    gl.bindTexture(gl.TEXTURE_2D, texture)
    gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, 1)
    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, video)
  }

  const texture = initTexture(gl)
  const video = setupVideo(sourceVideo)

  function render () {
    checkReady()
    if (copyVideo) {
      updateTexture(gl, texture, video)
    }
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4)
    requestAnimationFrame(render)
  }

  window.addEventListener('resize', () => {
    const width = document.querySelector(parent).clientWidth
    const height = document.querySelector(parent).clientHeight

    canvas.width = width * devicePixelRatio
    canvas.height = height * devicePixelRatio
    gl.canvas.width = width * devicePixelRatio
    gl.canvas.height = height * devicePixelRatio

    canvas.style.width = `${width}px`
    canvas.style.height = `${height}px`
    WebGLUtils.setUniform({
      context: gl,
      program,
      name: 'resolution',
      type: '2fv',
      data: [canvas.width, canvas.height]
    })
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height)
    if (copyVideo) {
      updateTexture(gl, texture, video)
    }
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4)
  })

  requestAnimationFrame(render)
}
